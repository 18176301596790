export const APP_DEFINITION_ID = '31ceda3c-e2c8-4e83-816d-ba293597730c';
export const WIDGET_ID = 'f6b6e28d-52b2-4de6-aab3-ff2ccad8e229';
export const HELP_ID = '2bcdc1c0-a128-4613-8eb2-6eabcedb3709';

export const BM_ROOT = 'wix-ai-assistant-client';
export const BM_PLAYGROUND = `${BM_ROOT}/playground`;
export const BM_SETTINGS = `${BM_ROOT}/settings`;
export const BM_QUOTA = `${BM_ROOT}/quota-usage`;

export const INBOX_BM_PAGE_ID = 'inbox';
export const PRESENCE_METADATA_URL =
  '_serverless/presence-api-metadata/v1/metadata';
