import React from 'react';

import { Button, ButtonPriority, ButtonSize } from 'wix-ui-tpa';

import { useLinkHandler } from 'AiAssistantWidget/hooks';

import { st, classes } from './LinkButton.st.css';

export type LinkButtonPriority =
  | ButtonPriority.primary
  | ButtonPriority.secondary;

export type LinkButtonSize = ButtonSize.medium | ButtonSize.tiny;

export const LinkButton: React.FC<{
  title: string;
  link: string;
  priority?: LinkButtonPriority;
  fullWidth?: boolean;
  size?: LinkButtonSize;
}> = ({
  title,
  link,
  priority = ButtonPriority.primary,
  fullWidth = false,
  size = ButtonSize.medium,
}) => {
  const { handleLinkClick, isOwn } = useLinkHandler(link);
  return isOwn ? (
    <Button
      className={st(classes.root, { priority, size })}
      onClick={handleLinkClick}
      priority={priority}
      upgrade
      fullWidth={fullWidth}
      size={size}
      wrapContent
    >
      {title}
    </Button>
  ) : (
    <a
      className={st(classes.root, { fullWidth, priority, size }, classes.link)}
      href={link}
      target="_blank"
      rel="noreferrer"
    >
      {title}
    </a>
  );
};
