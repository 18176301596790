import React, { DependencyList, useContext, useEffect } from 'react';

import { IWixEvents, useEnvironment, useWixSdk } from '@wix/yoshi-flow-editor';

import { SettingsEventDefinition } from 'AiAssistantWidget/settingsEvents';

import { OnHandlerEvent, OnHandlerReset } from '../../types';

export type EventHandler<Data> = (data: Data) => void;
export type ResetHandler = () => void;
// This dynamically adjusted as new components listen events.
const handlersPerEvent: Record<string, EventHandler<any>[]> = {};
const handlersOnReset: ResetHandler[] = [];

export type EditorSettingsEvents = {
  onHandlerEvent: OnHandlerEvent;
  onHandlerReset: OnHandlerReset;
};

export const EditorSettingsEventsContext =
  React.createContext<EditorSettingsEvents>({
    onHandlerEvent: () => {},
    onHandlerReset: () => {},
  });

export const EditorSettingsEventsProvider: React.FC<{
  onHandlerEvent: OnHandlerEvent;
  onHandlerReset: OnHandlerReset;
}> = ({ onHandlerEvent, onHandlerReset, children }) => (
  <EditorSettingsEventsContext.Provider
    value={{ onHandlerEvent, onHandlerReset }}
  >
    {children}
  </EditorSettingsEventsContext.Provider>
);

export function useOnEditorSettingsEvent<Data>(
  { eventName: name }: SettingsEventDefinition<Data>,
  handler: EventHandler<Data>,
) {
  const { onHandlerEvent } = useContext(EditorSettingsEventsContext);

  // Keys are never deleted as there is no option to unsubscribe after handler.on(***) ,
  // but the list will be empty if no one listens effectively making it do nothing
  useEffect(() => {
    if (!handlersPerEvent[name]) {
      handlersPerEvent[name] = [];
      onHandlerEvent(name, (v: unknown) =>
        // Here, the event data type is implicitly cast into `Data`.
        // Ideally, we should parse the incoming params from `unknown` to `Data`
        // but as we are the only users of this event hub it should be fine
        handlersPerEvent[name].forEach((h) => h(v)),
      );
    }
    // `name` is handled in return
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onHandlerEvent]);

  return useEffect(() => {
    handlersPerEvent[name].push(handler);
    return () => {
      const ind = handlersPerEvent[name].indexOf(handler);
      if (ind >= 0) {
        handlersPerEvent[name].splice(ind, 1);
      }
    };
  }, [handler, name]);
}

export function useOnEditorSettingsReset(
  handler: ResetHandler,
  deps: DependencyList,
) {
  const { onHandlerReset } = useContext(EditorSettingsEventsContext);
  const { Wix } = useWixSdk();
  const { isClassicEditor, isEditor } = useEnvironment();
  const isStudio = isEditor && !isClassicEditor;

  useEffect(() => {
    handlersOnReset.push(handler);
    return () => {
      const ind = handlersOnReset.indexOf(handler);
      if (ind >= 0) {
        handlersOnReset.splice(ind, 1);
      }
    };
  }, [handler, deps]);

  /**
   * There is bug in Studio/Viewer that public data is not updated after settings close
   * so we need to reset the handlers manually, subscribing on 'EDITOR_EVENT/TPA_ON_SETTINGS_CLOSE_EVENT'
   */
  useEffect(() => {
    const studioCb = (event: { type: string }) => {
      if (event?.type === 'TPA_ON_SETTINGS_CLOSE_EVENT') {
        handlersOnReset.forEach((h) => {
          h();
        });
      }
    };

    if (isStudio) {
      Wix?.addEventListener('EDITOR_EVENT' as IWixEvents, studioCb);
      return;
    }

    onHandlerReset(() => {
      handlersOnReset.forEach((h) => {
        h();
      });
    });

    return () => {
      if (isStudio) {
        (Wix as any)?.removeEventListener?.(
          'EDITOR_EVENT' as IWixEvents,
          studioCb,
        );
      }
    };
  }, [onHandlerReset, isStudio, Wix]);
}
